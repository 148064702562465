import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexLandingTwoComponent } from './core/components/index-landing-two/index-landing-two.component';


const routes: Routes = [
  {
    path: '',
    component: IndexLandingTwoComponent,
    
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
