import { Component, OnInit } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from 'axios';

@Component({
  selector: 'app-index-landing-two',
  templateUrl: './index-landing-two.component.html',
  styleUrls: ['./index-landing-two.component.css']
})
export class IndexLandingTwoComponent implements OnInit {

  email: string = "";
  constructor() { }

  ngOnInit(): void {
  }

  submitEmailToMailingList(){
    axios.post("https://us-central1-mysteryroyale-89a47.cloudfunctions.net/addMailingListEmail", {email: this.email})
    .then((res) => {
      window.alert("Success");
    }).catch(err => {
      window.alert("Invalid Email");
    });
  }

  emailChange(email){
    this.email = email;
  }
}
